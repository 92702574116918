import { authAgentRoles } from 'app/agent';
import AgentProfile from './agentprofile/AgentProfile';
import AgentProfileEdit from './agentprofile/AgentProfileEdit';
import AgentProfileAccount from './agentprofile/AgentProfileAccount';
import PublicFriendsManager from "app/main/apps/public/friends-manager/PublicFriendsManager";
import PublicMissionManager from "app/main/apps/public/mission-manager-v2/PublicMissionManager";
import PublicFriendsActivity from "app/main/apps/public/friends-activity/FriendsActivity";

const AgentAppConfig = {
    settings: {
        type: "public",
        theme: {
            main: "default",
            navbar: "default",
            toolbar: "default",
            footer: "default"
        },
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authAgentRoles.agent,
    routes: [
        {
            path: 'agentprofile',
            element: <AgentProfile />,
        },
        {
            path: 'agentprofile/account',
            element: <AgentProfileAccount />,
        },
        {
            path: 'agentprofile/edit',
            element: <AgentProfileEdit />,
        },
        {
            path: 'agentprofile/new-user',
            element: <AgentProfileEdit />,
        },
        {
            path: "public/friends-manager",
            element: <PublicFriendsManager />,
        },
        {
            path: "public/mission-manager",
            element: <PublicMissionManager />,
        },
        {
            path: "public/friends-activity",
            element: <PublicFriendsActivity />,
        },
    ],
};

export default AgentAppConfig;
