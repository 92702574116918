const query = {
  recentItemsList: `
    query recentItemsList {
        recentItemsList {
          recent{
            id,
            title,
            description,
            missionBriefing,
            lat,
            lng,
            address,
            agent,
            views,
            status,
            featured,
            dateCreated,
            dateUpdated,
            missionsFile{
              preSignedUrl
            },
            agents{
              id,
              email,
              agentName
              firstName,
              lastName,
              middleName,
              gender,
              birthdate,
              status,
              images{
                profileUrl
              }
            }
          },
          featured{
            id
         		title,
            description,
            missionBriefing,
            lat,
            lng,
            address,
            agent,
            views,
            status,
            featured,
            dateCreated,
            dateUpdated,
            missionsFile{,
              preSignedUrl
            },
            agents{
              id,
              email,
              agentName
              firstName,
              lastName,
              middleName,
              gender,
              birthdate,
              status,
              images{
                profileUrl
              }
            }
          },
          popular{
            id
         		title,
            description,
            missionBriefing,
            lat,
            lng,
            address,
            agent,
            views,
            status,
            featured,
            dateCreated,
            dateUpdated,
            missionsFile{,
              preSignedUrl
            },
             agents{
              id,
              email,
              agentName
              firstName,
              lastName,
              middleName,
              gender,
              birthdate,
              status,
              images{
                profileUrl
              }
            }
          }
        }
      }
  `,
  getPopularMission: `
    query popularItemsList{
        popularItemsList {
          title,
          description,
          missionsFile{
            fileName,
            preSignedUrl
          }
        }
      }
  `,
  getFeaturedMission: `
    query featureItemList {
        featuredItemsList {
          id
          title
          description
          missionBriefing
          lat
          lng
          address
          agent
          views
          status
          featured
          dateCreated
          dateUpdated
          missionsFile{
            preSignedUrl
          }
        }

      }
  `,
  homeMissionItems: `
    query homeMissionItems($module: String, $start: Int, $end: Int, $timezone: String) {
        homeMissionItems(module: $module, start: $start, end: $end, timezone: $timezone) {
            id
            filesId
            title
            description
            views
            featured
            pinned
            reach
            missionPhotoPath
            pinned
            agent{
                userId
                name
                firstName
                username
                profilePicPath
            }
            dateCreated
            formattedDate(timezone: $timezone)
            timeAgo
        }
    }
  `,
};

export { query };
