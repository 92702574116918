// Updated By   : ICO
// Date         : 07-20-2023
// Branch       : design-101
// Task #71
// Task Link    : https://app.activecollab.com/187150/projects/7?modal=Task-54731-7
// Details      : Apply Design from Adobe Xd to Web Design Development
//                Responsive From Desktop to mobile size

// NOTE! 
// TextFieldStyled dapat naka variant="filled"
// add this code on Inside of TextField
// InputLabelProps={{
//     sx: {
//       color: "#48525C",
//       fontSize:{xl:'16px',lg:'16px', md:'14px'},
//       [`&.${inputLabelClasses.shrink}`]: {
//       fontSize:'14px',
//       color: !!errors.username?"red":"#141F2C"
//       }
//     }
//   }}

import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { registerAgent, checkUsernameAndEmail } from "app/main/apps/public/register/store/publicRegisterSlice"
import * as yup from "yup";
import _ from "@lodash";
import Typography from '@mui/material/Typography';
import JwtService from "app/services/jwtService";
import { useNavigate } from 'react-router-dom';
import { Divider, Link } from "@mui/material";
import { showMessage } from "app/store/fuse/messageSlice";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { Paper, styled } from '@mui/material';
import { borderRadius } from "@mui/system";

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        height: 48,
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            height: 57,
            fontSize: '18px',
        },
        // borderRadius:"8px"
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
}));

const defaultValues = {
    username: "",
    email: "",
    password: "",
    password2: "",
};

function PublicRegisterTab(props) {
    const navigate = useNavigate();
    /**
     * Form Validation Schema
     */
    const schema = yup.object().shape({
        username: yup
            .string()
            .required("You must enter a username")
            .test({
                message: () => 'Username already exists.',
                test: async (id) => {
                    if (id) {
                        return dispatch(checkUsernameAndEmail({ value: id }));
                    }
                },
            }),
        email: yup
            .string()
            .required("You must enter a email")
            .email("You must enter a valid email")
            .test({
                message: () => 'Email already exists.',
                test: async (id) => {
                    if (id) {
                        return dispatch(checkUsernameAndEmail({ value: id }));
                    }
                },
            }),
        password: yup
            .string()
            .required("Please enter your password.")
            .min(4, "Password is too short - should be 4 chars minimum."),
        password2: yup
            .string()
            .required("Please confirm your password."),
    });

    const dispatch = useDispatch();
    const login = useSelector(({ auth }) => auth.login);

    const {
        control,
        setValue,
        getValues,
        formState,
        handleSubmit,
        reset,
        trigger,
        watch,
        setError,
    } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [pass2Message, setPass2Message] = useState('');

    const password = watch('password');
    const password2 = watch('password2');

    useEffect(() => {
        if (password != password2 && password && password2) {
            setPass2Message("Passwords don't match.");
        } else {
            setPass2Message('');
        }
    }, [password, password2])

    function onSubmit(model) {
        dispatch(registerAgent(model)).then((action) => {
            if (action.payload.status == 'success') {
                reset(defaultValues);
                navigate("/public/register/success")
            } else {
                dispatch(
                    showMessage({
                        message: action.payload.message,
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
        });
    }

    return (
        <div className="w-full max-w-pie-382px">
            <form
                className="flex flex-col justify-center w-full"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Controller
                    name="username"
                    control={control}
                    render={({ field }) => (
                        <TextFieldStyled
                            label="Username"
                            placeholder="Enter your username"
                            variant="filled"
                            InputLabelProps={{
                                sx: {
                                    color: "#48525C",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        fontSize: '14px',
                                        color: !!errors.username ? "red" : "#141F2C"
                                    }
                                }
                            }}
                            {...field}
                            className=" mb-24"
                            type="text"
                            error={!!errors.username}
                            helperText={errors?.username?.message}
                        />
                    )}
                />
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <TextFieldStyled
                            label="Email Address"
                            placeholder="Enter your email address"
                            variant="filled"
                            InputLabelProps={{
                                sx: {
                                    color: "#48525C",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        fontSize: '14px',
                                        color: !!errors.email ? "red" : "#141F2C"
                                    }
                                }
                            }}
                            {...field}
                            className=" mb-24"
                            type="text"
                            error={!!errors.email}
                            helperText={errors?.email?.message}
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <TextFieldStyled
                            label="Password"
                            placeholder="******"
                            variant="filled"
                            InputLabelProps={{
                                sx: {
                                    color: "#48525C",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        fontSize: '14px',
                                        color: !!errors.password ? "red" : "#141F2C"
                                    }
                                }
                            }}
                            {...field}
                            className=" mb-24"
                            type="password"
                            error={!!errors.password}
                            helperText={errors?.password?.message}
                            InputProps={{
                                className: "pr-2",
                                type: showPassword ? "text" : "password",
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            size="large"
                                            edge={false}
                                        >
                                            <Icon className="text-20" color="action">
                                                {showPassword ? "visibility" : "visibility_off"}
                                            </Icon>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            required
                        />
                    )}
                />
                <Controller
                    name="password2"
                    control={control}
                    render={({ field }) => (
                        <TextFieldStyled
                            label="Confirm Password"
                            placeholder="******"
                            variant="filled"
                            InputLabelProps={{
                                sx: {
                                    color: "#48525C",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        fontSize: '14px',
                                        color: !!errors.password2 ? "red" : "#141F2C"
                                    }
                                }
                            }}
                            {...field}
                            className=" mb-24"
                            type="password2"
                            error={!!errors.password2 || pass2Message}
                            helperText={pass2Message ? pass2Message : errors?.password2?.message}
                            InputProps={{
                                className: "pr-2",
                                type: showPassword2 ? "text" : "password",
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword2(!showPassword2)}
                                            size="large"
                                            edge={false}
                                        >
                                            <Icon className="text-20" color="action">
                                                {showPassword2 ? "visibility" : "visibility_off"}
                                            </Icon>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            required
                        />
                    )}
                />
                <Button
                    type="submit"
                    variant="contained"
                    className="w-full mx-auto mt-0 font-bold text-14 lg:text-16 bg-pi-yellow-green"
                    aria-label="SIGN UP"
                    disabled={_.isEmpty(dirtyFields) || !isValid || pass2Message}
                    value="legacy"
                    size="large"
                >
                    SIGN UP
                </Button>
            </form>
        </div>
    );
}

export default PublicRegisterTab;
