import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "../graphql/home_graphql";

export const recentItemsList = createAsyncThunk(
    "missionApp/mission/recentItemsList",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.recentItemsList,
        });
        return response.data.data.recentItemsList;
    }
);

export const getPopularMission = createAsyncThunk(
    "missionApp/mission/getPopularMission",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getPopularMission
        });
        return response.data.data.popularItemsList;
    }
);

export const getFeaturedMission = createAsyncThunk(
    "missionApp/mission/getFeaturedMission",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getFeaturedMission,
        });
        return response.data.data.featuredItemsList;
    }
);

export const homeMissionItems = createAsyncThunk(
    "missionApp/mission/homeMissionItems",
    async (params) => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.homeMissionItems,
            variables: {
                module: params.module,
                start: params.start,
                end: params.end,
                timezone: timeZone
            }
        });

        if (response.data.data.homeMissionItems) {
            const data = response.data.data.homeMissionItems;

            return data === undefined ? [] : data;
        } else {
            return [];
        }
    }
);

const homeSlice = createSlice({
    name: "mission",
    initialState: null,
    reducers: null,
    extraReducers: {
        [recentItemsList.fulfilled]: (state, action) => action.payload,
        [getFeaturedMission.fulfilled]: (state, action) => action.payload,
        [getPopularMission.fulfilled]: (state, action) => action.payload,
    },
});

export default homeSlice.reducer;
